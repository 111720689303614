import React from 'react';

import styled from 'styled-components';

interface ComponentProps {}

const CopyrightBar: React.FC<ComponentProps> = () => {
  return <CopyrightWrapper>© Penzion U Kašny | Ochrana osobnich udaju</CopyrightWrapper>;
};

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1.2rem;
  background-color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #000000;
`;

export default CopyrightBar;
