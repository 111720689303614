import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

interface ComponentProps {}

const Logo: React.FC<ComponentProps> = () => {
  return (
    <LogoWrapper>
      <Link href={'/'}>
        <a>
          <LogoImg src={'/icons/fountain-logo.svg'} />
        </a>
      </Link>
      <LogoText>Penzion U kašny</LogoText>
    </LogoWrapper>
  );
};

const LogoImg = styled.img``;

const LogoText = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 180px;
  gap: 19px;
  padding: 19px 27px;

  @media screen and (max-width: 720px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;
export default Logo;
