import React from 'react';
import styled from 'styled-components';

interface ComponentProps {}

const FooterBar: React.FC<ComponentProps> = () => {
  return (
    <FooterWrapper>
      <div>
        <p>INDIVIDUAL STARTUP s.r.o.</p>
        <p>Rybná 716/24, Staré Město</p>
      </div>
      <div>
        <p>110 00 Praha 1</p>
        <p>Česká republika</p>
      </div>
      <div>
        <p>+420 608 965 936</p>
        <p>
          <a href={'mailto:miloslav.vlach@gmail.com'} style={{ color: 'white' }}>
            miloslav.vlach@gmail.com
          </a>
        </p>
      </div>
      <div>
        <p>IČ: 08 94 96 71</p>
        <p>DIČ: CZ08 94 96 71</p>
      </div>
      <div>
        {/*<Icon src={'/icons/instagram.svg'} />*/}
        <a href={'https://www.facebook.com/Penzion-U-Ka%C5%A1ny-107379837633233'} target={'_new'}>
          <Icon src={'/icons/facebook.svg'} />
        </a>
      </div>
    </FooterWrapper>
  );
};

const Icon = styled.img``;
const FooterWrapper = styled.div`
  display: flex;
  background-color: #461a1d;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;

  div {
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #ffffff;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    div {
      display: flex;
      font-size: 16px;
      margin-top: 1.5rem;
      justify-content: center;
      flex-direction: column;
      * {
        text-align: center;
      }
    }
  }
`;

export default FooterBar;
