import styled from 'styled-components';
import Head from 'next/head';
import React, { useEffect } from 'react';
import HeaderPanel from '../front/HeaderPanel/HeaderPanel';
import LogoPanel from '../LogoPanel/LogoPanel';
import FeatureBar from '../FeatureBar/FeatureBar';
import FooterBar from '../FooterBar/FooterBar';
import CopyrightBar from '../CopyrightBar/CopyrightBar';
import SearchFormPanel from '../front/SearchFormPanel/SearchFormPanel';

interface ComponentProps {
  pageTitle: string;
}

export const Layout: React.FC<ComponentProps> = ({ children, pageTitle }) => {
  return (
    <>
      <Head>
        <title>UbytovaniUKasny.cz - {pageTitle}</title>
        <meta name="description" content="UbytovaniUKasny.cz - ubytování v České Kamenici." />
        <meta property="og:title" content="UbytovaniUKasny.cz - ubytování v České Kamenici." />
        <meta property="og:description" content="UbytovaniUKasny.cz - ubytování v centru České Kamenice" />
      </Head>
      <Homepage>
        <CenteredColumn>
          <HeaderPanel />
          <LogoPanel />

          <FeatureBar />
          <SearchFormPanel />
          <ContentWrapper>
            {/*<Note>Restaurace je dočasně uzavřena.</Note>*/}
            {children}
          </ContentWrapper>
          <FooterBar />
          <CopyrightBar />
        </CenteredColumn>
      </Homepage>
    </>
  );
};

const Note = styled.div`
  display: flex;
  display: flex;
  background-color: #461a1d;
  justify-content: center;
  padding: 0 1rem;
  padding: 10px;
  color: white;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 2rem;
  background-color: white;
  flex-direction: column;
`;

const CenteredColumn = styled.div`
  display: flex;
  max-width: 900px;
  margin: auto;
  width: 100%;
  flex-direction: column;
`;

const Main = styled.main`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.875rem;
`;
const Homepage = styled.div``;
