import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '../../hooks/i18n/useTranslation';

interface ComponentProps {}

const LogoPanel: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <LogoWrapper>
      <Box>
        <BoxHeading>{tr(`LogoPanel.ubytování`, `Ubytování`)}</BoxHeading>
        <BoxParagraph>{tr(`LogoPanel.vHistorickémMěstečkuBlízko`, `v historickém městečku blízko`)}</BoxParagraph>
        <BoxParagraph>{tr(`LogoPanel.národníhoParkuČeskéŠvýcarsko`, `Národního parku České Švýcarsko`)}</BoxParagraph>
      </Box>
    </LogoWrapper>
  );
};

const BoxParagraph = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  width: 180px;

  color: #ffffff;
`;

const BoxHeading = styled.div`
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #ffffff;
`;

const Box = styled.div`
  display: flex;
  position: relative;

  padding: 2rem;

  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin: auto 3rem auto 0;
  left: 34px;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 10px;
`;

const LogoWrapper = styled.div`
  background-image: url('https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/data/2021_Ceska_Kamenice_zima_vyber347.jpg?width=900');
  display: flex;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -100px;
  width: 100%;
  min-height: 241px;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export default LogoPanel;
