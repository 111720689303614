import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { urlQueryToSearchParams } from 'next/dist/next-server/lib/router/utils/querystring';
import { useTranslation } from 'next-i18next';

interface ComponentProps {}

export enum Page {
  RESERVATIONS,
}

interface PageInfo {
  page: Page;
  urlPattern: string;
}

interface Params {
  applicationParams?: any;
  queryParams?: any;
}

const pages: PageInfo[] = [{ page: Page.RESERVATIONS, urlPattern: '/reservations' }];

const useNavigation = () => {
  const router = useRouter();
  const { i18n } = useTranslation();

  const navigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    router.push(url);
  };

  const createNavigationLink = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    return url;
  };

  const hardNavigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    window.location.href = i18n.language + url;
  };

  const silentNavigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    router.push(url, url, { shallow: true });
  };

  return { navigate, createNavigationLink, hardNavigate, silentNavigate };
};

const interpolate = (value: string, params: Params) => {
  const queryParams = new URLSearchParams(params.queryParams).toString();

  if (Object.keys(params.applicationParams || {}).length == 0) return `${value}?${queryParams}`;
  const interpolatedString = Object.keys(params.applicationParams).reduce((res, item) => {
    return res.replace(`{${item}}`, params.applicationParams[item]);
  }, value);

  return `${interpolatedString}?${queryParams}`;
};

export default useNavigation;
