import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';

interface ComponentProps {}

const Menu: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <MenuWrapper>
      <MenuInner>
        <Link href={'/'}>
          <MenuItem>{tr(`Menu.homepage`, `Úvod`)}</MenuItem>
        </Link>
        <Link href={'/prices'}>
          <MenuItem>{tr(`Menu.prices`, `Ceny pokojů`)}</MenuItem>
        </Link>
        <Link href={'/contact'}>
          <MenuItem>{tr(`Menu.contact`, `Kontakt`)}</MenuItem>
        </Link>
        <Link href={'https://www.officeontheroad.cz'}>
          <MenuItem>{tr(`Menu.motorhomeForRent`, `Obytňák k pronájmu`)}</MenuItem>
        </Link>
      </MenuInner>
    </MenuWrapper>
  );
};

const Language = styled.div`
  margin-left: 1rem;
  display: flex;
`;
const MenuInner = styled.div`
  display: flex;
  gap: 26px;
`;

const MenuItem = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: flex;
  padding: 19px 27px;
  flex-grow: 1;
  justify-content: flex-end;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export default Menu;
