import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useNavigation, { Page } from '../../../hooks/useNavigation';
import { useRouter } from 'next/router';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../lib/utils';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { useTranslation } from 'next-i18next';
import { CheckReservationOutDTO } from '../../../api';

interface ComponentProps {}

interface FormData {
  since: Date;
  till: Date;
  guests: string;
}

const validationSchema = yup.object().shape({});
const initialValues: FormData = {
  till: dayjs(new Date()).add(1, 'days').toDate(),
  guests: '2',
  since: new Date(),
};

const SearchFormPanel: React.FC<ComponentProps> = () => {
  const [result, setResult] = useState<CheckReservationOutDTO[]>();
  const { hardNavigate } = useNavigation();
  const router = useRouter();
  const inputGuestRef = useRef(undefined);
  const { tr } = useTranslationLgs();

  const guests = router.query['guests'] as string;
  const since = router.query['localDateSince'] as string;
  const till = router.query['localDateTill'] as string;

  const formik = useFormik<FormData>({
    validationSchema,
    onSubmit: (values) => {},
    initialValues,
  });

  const handleCheckReservation = () => {
    hardNavigate(Page.RESERVATIONS, {
      queryParams: {
        guests: parseInt(formik.values.guests),
        localDateSince: dayjs(formik.values.since).format('YYYY-MM-DD'),
        localDateTill: dayjs(formik.values.till).format('YYYY-MM-DD'),
        resourceGroupUUID: 'd2b8361c-a5f6-11ec-b75a-960000dc55d4',
      },
    });
  };

  useEffect(() => {
    if (isNotNullOrUndefined(guests)) {
      formik.setFieldValue('guests', guests);
    }
    if (isNotNullOrUndefined(since)) {
      formik.setFieldValue('since', dayjs(since).toDate());
    }
    if (isNotNullOrUndefined(till)) {
      formik.setFieldValue('till', dayjs(till).toDate());
    }
  }, [since, till, guests]);

  const isDisabled = () => {
    if (isNaN(parseInt(formik.values.guests))) return true;
    if (isNullOrUndefined(formik.values.till)) return true;
    if (isNullOrUndefined(formik.values.since)) return true;
    return false;
  };

  const focusInputGuest = () => {
    inputGuestRef?.current.focus();
  };

  const { i18n } = useTranslation();

  return (
    <>
      {router.isReady && (
        <a id={'form'}>
          <form>
            <FormWrapper>
              <Calendar
                placeholder={tr(`SearchFormPanel.since`, `Kdy chcete prijet`)}
                locale={i18n.language}
                dateFormat={'d.m.y'}
                inputStyle={{ textAlign: 'center' }}
                value={formik.values.since}
                onChange={(e) => formik.setFieldValue('since', e.value)}
                showIcon={true}
                style={{ width: '100%' }}
              />
              <Calendar
                placeholder={tr(`SearchFormPanel.till`, `Kdy chcete odjet`)}
                dateFormat={'d.m.y'}
                locale={i18n.language}
                inputStyle={{ textAlign: 'center' }}
                value={formik.values.till}
                onChange={(e) => formik.setFieldValue('till', e.value)}
                showIcon={true}
                style={{ width: '100%' }}
              />
              {/*            <ChooseCalendar
              value={{ since: toDayInfo(formik.values.since), till: toDayInfo(formik.values.till) }}
              lazyDisabledDays={(since, till) => {
                return [];
              }}
              onIntervalChange={(interval) => {
                formik.setFieldValue('since', dayInfo2Dayjs(interval.since).format('YYYY-MM-DD'));
                formik.setFieldValue('till', dayInfo2Dayjs(interval.till).format('YYYY-MM-DD'));
              }}
            />*/}
              {/*<NumberOfGuests>
                <InputText
                  ref={inputGuestRef}
                  style={{
                    border: 0,
                    outline: 0,
                    fontSize: '1rem',
                    width: '100%',
                    textAlign: 'center',
                  }}
                  placeholder={tr(`SearchFormPanel.guestCount`, `Pocet osob`)}
                  value={formik.values.guests}
                  onChange={(event) => formik.setFieldValue('guests', event.target.value)}
                />
              </NumberOfGuests>*/}
              <SpanButton
                onClick={(e) => {
                  if (!isDisabled()) {
                    // @ts-ignore
                    window.gtag('event', 'search_reservation', formik.values);
                    handleCheckReservation();
                  }
                }}
                disabled={isDisabled()}
              >
                <NoWrap>{tr(`SearchFormPanel.search`, `Vyhledat volne pokoje`)}</NoWrap>
              </SpanButton>
            </FormWrapper>
          </form>
        </a>
      )}
    </>
  );
};

const NoWrap = styled.div`
  white-space: nowrap;
`;

const RowWithNote = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding: 0.4rem;
  }
`;

const SpanButton = styled.div`
  display: flex;
  background-color: #461a1d;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 18px 44px;
  cursor: pointer;
  justify-content: center;
  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      background-color: rgba(70, 26, 29, 0.29);
    `}
`;

const NumberOfGuests = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 0 2rem;
  gap: 30px;
`;

const FormWrapper = styled.div`
  background-color: #d9d9d9;
  display: flex;
  gap: 1rem;
  padding: 17px 10px;
  position: relative;

  .p-button {
    background-color: #461a1d;
  }

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export default SearchFormPanel;
