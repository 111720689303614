import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '../../hooks/i18n/useTranslation';

interface ComponentProps {}

const FeatureBar: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <FeatureBarWrapper>
      <FeatureList>
        <FeatureItem>
          <Icon src={'/icons/wifi.svg'} style={{ height: '22px' }} />
          <FeatureText>{tr(`FeatureBar.freeWifi`, `free wifi`)}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <Icon src={'/icons/frigorifero.svg'} style={{ height: '22px' }} />
          <FeatureText>{tr(`FeatureBar.fridge`, `lednice na pokoji`)}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <Icon src={'icons/televisiore.svg'} />
          <FeatureText>{tr(`FeatureBar.tv`, `televize na pokoji`)}</FeatureText>
        </FeatureItem>
        {/*        <FeatureItem>
          <Icon src={'icons/ristorante.svg'} />
          <FeatureText>{tr(`FeatureBar.restaurant`, `restaurace`)}</FeatureText>
        </FeatureItem>{' '}*/}
        <FeatureItem>
          <Icon src={'icons/dog.svg'} style={{ height: '22px', color: 'white' }} />
          <FeatureText>{tr(`FeatureBar.welcome`, `vítáni`)}</FeatureText>
        </FeatureItem>
      </FeatureList>
    </FeatureBarWrapper>
  );
};

const FeatureList = styled.div`
  gap: 46px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    justify-content: space-between;
  }
`;

const FeatureBarWrapper = styled.div`
  display: flex;
  background-color: #461a1d;
  justify-content: center;
  padding: 0 1rem;
`;

const Icon = styled.img``;

const FeatureText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: none;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0.5rem 0;
`;

export default FeatureBar;
