import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import Link from 'next/link';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';

interface ComponentProps {}

const HeaderPanel: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [visibleMenu, setVisibleMenu] = useState(true);
  return (
    <MenuContainer>
      <HeaderWrapper>
        <Logo />
        <Menu />
        <LanguageRow>
          <Language>
            <Link href={'/'} locale={'de'}>
              <span className="fi fi-de "></span>
            </Link>
          </Language>
          <Language>
            <Link href={'/'} locale={'cs'}>
              <span className="fi fi-cz "></span>
            </Link>
          </Language>
          <Language>
            <Link href={'/'} locale={'en'}>
              <span className="fi fi-gb"></span>
            </Link>
          </Language>
        </LanguageRow>
        <MenuBurger onClick={(e) => setVisibleMenu((t) => !t)}>
          <div></div>
          <div></div>
          <div></div>
        </MenuBurger>
      </HeaderWrapper>
      {visibleMenu && (
        <>
          <ResponsiveMenu>
            <Row>
              <Link href={'/prices'}>
                <MenuItem>{tr(`HeaderPanel.prices`, `Ceny pokojů`)}</MenuItem>
              </Link>
              <Link href={'/contact'}>
                <MenuItem>{tr(`HeaderPanel.contact`, `Kontakt`)}</MenuItem>
              </Link>
              <Link href={'https://www.officeontheroad.cz'}>
                <MenuItem>{tr(`HeaderPanel.motorHomeForRent`, `Obytňák k pronájmu`)}</MenuItem>
              </Link>
            </Row>
          </ResponsiveMenu>
        </>
      )}
    </MenuContainer>
  );
};

const Language = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

const MenuBurger = styled.div`
  @media (max-width: 720px) {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 2rem;
      height: 0.31rem;
      background-color: ${({ open }) => (open ? 'rgb(3, 131, 163)' : 'black')};
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0)')};
      }

      :nth-child(2) {
        transform: ${({ open }) => (open ? 'translate(-12px, -8.5px) rotate(90deg)' : 'translateX(0)')};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? 'translate(12px, -16.5px)rotate(90deg)' : 'rotate(0)')};
      }
    }
  }
`;

const MenuItem = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
`;

const ResponsiveMenu = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  @media screen and (max-width: 720px) {
    display: flex;
    padding: 0rem 0.5rem 1rem 0.5rem;
    column-gap: 2rem;
  }
`;

const LanguageRow = styled.div`
  display: flex;
  margin-right: 1rem;
  gap: 0.5rem;
`;

const MenuContainer = styled.div`
  position: relative;
  background-color: white;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

export default HeaderPanel;
